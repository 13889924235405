import React, { useState } from "react";

export default function Pricing() {
  const [activeIndex, setActiveIndex] = useState(0);
  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? 0 : index);
  };

  return (
    <div className="wrapper-content">
      <div className="div-block-97">
        <div className="hero wf-section">
          <div className="container mb-64 w-container">
            <div className="modul-text">
              <div className="div-block-94">
                <h1 className="xl1 align-center mb-16">
                  <span>Simple, </span>
                  <span className="underline-text">Fair </span>{" "}
                  <span>Pricing.</span>
                </h1>
                <div className="cont-text-snd-alt-2">
                  <h2 className="s text-lightgrey align-center">
                    Only pay for what you get documented. Plan per Repository.
                  </h2>
                </div>
              </div>
            </div>
            <div className="div-block-158">
              <div className="div-block-98-copy"></div>
              <div className="div-block-147">
                <div className="div-block-150">
                  <div>
                    <div className="div-block-153">
                      <div className="body-s">STARTUP</div>
                    </div>
                    <div className="div-block-154">
                      <div className="m">$499</div>
                    </div>
                    <div className="div-block-155">
                      <div className="m opacity-80">
                        For testing and small projects.
                      </div>
                    </div>
                    <div className="div-block-156">
                      <ul className="list-2">
                        <li className="list-item">1 Repo & upto 30 files</li>
                        <li className="list-item">Hosted Codesplain</li>
                        <li className="list-item">Custom Domain</li>
                        <li className="list-item">Single Codebase</li>
                      </ul>
                    </div>
                    <div className="div-block-157">
                      <a
                        data-tb-track-section="pricing-table"
                        data-tb-track-click="signup_cta_click"
                        href="https://app.codesplain.live/"
                        className="main-button w-inline-block"
                      >
                        <p className="body-s">Try Documentation</p>
                      </a>
                    </div>
                  </div>
                  <div className="div-block-131-copy">
                    <div className="xs">Refund Policy Applicable.</div>
                  </div>
                </div>
                <div className="div-block-133-copy">
                  <div className="div-block-149">
                    <div className="div-block-152">
                      <div className="body-s">Recommended</div>
                    </div>
                    <div>
                      <div className="div-block-153">
                        <div className="body-s">GROWTH</div>
                      </div>
                      <div className="div-block-154">
                        <div className="m">Pay as you grow</div>
                      </div>
                      <div className="div-block-155">
                        <div className="m opacity-80">
                          Recommended for production.
                        </div>
                      </div>
                      <div className="div-block-156">
                        <ul className="list-2">
                          <li className="list-item">3 repo & upto 100 files</li>
                          <li className="list-item">Hosted Codesplain</li>
                          <li className="list-item">Custom Domain of Choice</li>
                          <li className="list-item">Public & Private View</li>
                          <li className="list-item">Multiple Codebase</li>
                        </ul>
                      </div>
                      <div className="div-block-157">
                        <a
                          data-tb-track-section="pricing-table"
                          data-tb-track-click="signup_cta_click"
                          href="https://app.codesplain.live/"
                          className="main-button w-inline-block"
                        >
                          <p className="body-s">Try Documentation</p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="div-block-148">
                  <div>
                    <div className="div-block-153">
                      <div className="body-s">ENTERPRISE</div>
                    </div>
                    <div className="div-block-154">
                      <div className="m">Growing Teams</div>
                    </div>
                    <div className="div-block-155">
                      <div className="m opacity-80">
                        Fully featured and suited to your needs.
                      </div>
                    </div>
                    <div className="div-block-156">
                      <ul className="list-2">
                        <li className="list-item">
                          Unlimited Repo & Unlimited Files
                        </li>
                        <li className="list-item">
                          Hosted Codesplain Documentation
                        </li>
                        <li className="list-item">Custom Domain of Choice</li>
                        <li className="list-item">Public & Private View</li>
                        <li className="list-item">Invite Team & Collabrate</li>
                        <li className="list-item">Multiple Codebase</li>
                        <li className="list-item">Code Updates & Versioning</li>
                      </ul>
                    </div>
                    <div className="div-block-157">
                      <a
                        href="mailto:support@codesplain.live"
                        data-tb-track-section="pricing-table"
                        data-tb-track-click="contact_sales_cta_click"
                        className="main-button-copyasd w-inline-block"
                      >
                        <p className="body-s">Contact sales</p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="div-block-143">
              <div
                id="w-node-c228fb06-f287-639f-2ce2-05fb16045b53-894428d9"
                className="div-block-144"
              >
                <h3 className="l-semibold">All the plans Include</h3>
              </div>
              <div className="div-block-145">
                <div className="div-block-146">
                  <img
                    src="/pricing/icon-check.png"
                    loading="lazy"
                    alt=""
                    className="image-52"
                  />
                  <div>
                    <div className="body-s">Github Integration</div>
                  </div>
                </div>
                <div className="div-block-146">
                  <img
                    src="/pricing/icon-check.png"
                    loading="lazy"
                    alt=""
                    className="image-52"
                  />
                  <div>
                    <div className="body-s">
                      VS Code Integration <sup>soon</sup>
                    </div>
                  </div>
                </div>
                <div className="div-block-146">
                  <img
                    src="/pricing/icon-check.png"
                    loading="lazy"
                    alt=""
                    className="image-52"
                  />
                  <div>
                    <div className="body-s">Annotation & Explanation</div>
                  </div>
                </div>
                <div className="div-block-146">
                  <img
                    src="/pricing/icon-check.png"
                    loading="lazy"
                    alt=""
                    className="image-52"
                  />
                  <div>
                    <div className="body-s">Multiple Languages</div>
                  </div>
                </div>
              </div>
              <div className="div-block-145">
                <div className="div-block-146">
                  <img
                    src="/pricing/icon-check.png"
                    loading="lazy"
                    alt=""
                    className="image-52"
                  />
                  <div>
                    <div className="body-s">Hosted Codesplain</div>
                  </div>
                </div>
                <div className="div-block-146">
                  <img
                    src="/pricing/icon-check.png"
                    loading="lazy"
                    alt=""
                    className="image-52"
                  />
                  <div>
                    <div className="body-s">AI Documentation</div>
                  </div>
                </div>
                <div className="div-block-146">
                  <img
                    src="/pricing/icon-check.png"
                    loading="lazy"
                    alt=""
                    className="image-52"
                  />
                  <div>
                    <div className="body-s">Secured data</div>
                  </div>
                </div>
                <div className="div-block-146">
                  <img
                    src="/pricing/icon-check.png"
                    loading="lazy"
                    alt=""
                    className="image-52"
                  />
                  <div>
                    <div className="body-s">Codesplain Support</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="section-enterprise-plan"
        className="section-enterprise-plan wf-section"
      >
        <div className="container w-container">
          <div className="div-block-117">
            <div className="div-block-119">
              <div className="div-block-118">
                <p className="body-s-copy">
                  <span className="featured-line">Volume discounts</span>
                </p>
              </div>
              <div className="div-block-124">
                <div className="l-semibold">Enterprise Plan</div>
              </div>
            </div>
            <div className="div-block-120">
              <h3 className="xl large-lh">
                Volume Discount for{" "}
                <span className="underline-text">bulk codebase</span>.
              </h3>
            </div>
            <div className="div-block-122">
              <div className="enterprise-features-item">
                <img
                  src="/pricing/sla-icon.png"
                  loading="lazy"
                  alt=""
                  className="image-51"
                />
                <div className="div-block-123">
                  <h4 className="m2-semibold">SLA for 99.9% Uptime</h4>
                </div>
                <div>
                  <p className="s">
                    Pay for what you use, at a price you’ll know in advance.
                    It’s as simple as that.
                  </p>
                </div>
              </div>
              <div className="enterprise-features-item">
                <img
                  src="/pricing/dedicated.png"
                  loading="lazy"
                  alt=""
                  className="image-51"
                />
                <div className="div-block-123">
                  <h4 className="m2-semibold">Real-time Documentation</h4>
                </div>
                <div>
                  <p className="s">
                    Documentation is created as soon as you push the code.No
                    Waiting in line
                  </p>
                </div>
              </div>
              <div className="enterprise-features-item">
                <img
                  src="/pricing/support.png"
                  loading="lazy"
                  alt=""
                  className="image-51"
                />
                <div className="div-block-123">
                  <h4 className="m2-semibold">Dedicated Support</h4>
                </div>
                <div>
                  <p className="s">
                    Get started using Codesplain for knowledge management, and
                    you'll never stop using it.
                  </p>
                </div>
              </div>
            </div>
            <div className="div-block-121">
              <a
                data-tb-track-click="contact_sales_cta_click"
                data-tb-track-section="enterprise-plan"
                href="https://app.codesplain.live/"
                className="main-button secondary w-inline-block"
              >
                <p>Contact sales</p>
                <img
                  src="/pricing/icon-check.png"
                  loading="lazy"
                  alt=""
                  className="arrow-right-apply"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="div-block-89">
        <div className="container-alt">
          <div className="div-block-93">
            <div className="div-block-91 mb-24">
              <h3 className="xl">Frequently Asked Questions</h3>
            </div>
            <div className="div-block-92">
              <p className="s">
                In case you don&#x27;t find an answer here, write to us at{" "}
                <a href="mailto:support@codesplain.live" className="link-6">
                  support@codesplain.live
                </a>
              </p>
            </div>

            <div className="faqs-content">
              <div className="dropdown-object-2">
                <div
                  data-tb-track-faq-title="How is processed data calculated? "
                  data-tb-track-click="faq_item_expand"
                  data-w-id="cb6ba99d-b6fb-eae4-175d-3e2447e98039"
                  className="dropdown-toggle"
                  onClick={() => handleClick(0)}
                >
                  <h4 className="m2-semibold">
                    What is codebase knowledge management?{" "}
                  </h4>
                  <div className="dropdown-icon-wrapper">
                    <img
                      src="6048c50adefe73fe3bc6c51b/614989f13316e24900485667_angledown.svg"
                      loading="lazy"
                      alt=""
                      className="pricing-dropdown-icon"
                    />
                  </div>
                </div>
                {activeIndex === 0 ? (
                  <div
                    styles="height:0px;opacity:0"
                    className="dropdown-list-2"
                  >
                    <div className="div-block-160">
                      <p className="s">
                        Codebase knowledge management refers to the processes,
                        systems, and tools used to store, maintain, and share
                        information about the structure, design, and
                        functionality of an organization's codebase. This
                        includes code documentation, technical knowledge
                        transfer, and collaboration among software development
                        teams. The goal of codebase knowledge management is to
                        improve software development efficiency, reduce the risk
                        of knowledge loss, and ensure that the codebase remains
                        well-documented, accessible, and understandable over
                        time.
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="dropdown-object-2">
                <div
                  data-tb-track-faq-title="How do workspaces work?"
                  data-tb-track-click="faq_item_expand"
                  data-w-id="94b33d59-eb78-4504-5063-f80329a03672"
                  className="dropdown-toggle"
                  onClick={() => handleClick(1)}
                >
                  <h4 className="m2-semibold">
                    How it is different from documentation?
                  </h4>
                  <div className="dropdown-icon-wrapper">
                    <img
                      src="6048c50adefe73fe3bc6c51b/614989f13316e24900485667_angledown.svg"
                      loading="lazy"
                      alt=""
                      className="pricing-dropdown-icon"
                    />
                  </div>
                </div>
                {activeIndex === 1 ? (
                  <div
                    styles="height:0px;opacity:0"
                    className="dropdown-list-2"
                  >
                    <div className="div-block-160">
                      <p className="s">
                        Codebase knowledge management refers to the process of
                        organizing and preserving the knowledge associated with
                        a software codebase, including information about its
                        design, structure, and functionality. This information
                        helps developers understand and maintain the code, and
                        it also ensures that critical knowledge about the code
                        is not lost over time.
                      </p>
                      <p className="s">
                        Documentation, on the other hand, refers to written or
                        digital information that provides explanations,
                        instructions, and other relevant information about a
                        software system. Documentation is an important aspect of
                        codebase knowledge management, but it is just one aspect
                        of it.
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="dropdown-object-2">
                <div
                  data-tb-track-faq-title="Is storage billed per compressed data?"
                  data-tb-track-click="faq_item_expand"
                  data-w-id="a9656c71-7f62-4458-98c5-7f3522a877a2"
                  className="dropdown-toggle"
                  onClick={() => handleClick(2)}
                >
                  <h4 className="m2-semibold">
                    What is Codesplain's approach for documention?
                  </h4>
                  <div className="dropdown-icon-wrapper">
                    <img
                      src="6048c50adefe73fe3bc6c51b/614989f13316e24900485667_angledown.svg"
                      loading="lazy"
                      alt=""
                      className="pricing-dropdown-icon"
                    />
                  </div>
                </div>
                {activeIndex === 2 ? (
                  <div
                    styles="height:0px;opacity:0"
                    className="dropdown-list-2"
                  >
                    <div className="div-block-160">
                      <p className="s">
                        Codesplain utilizes an AI-driven approach to generate
                        comprehensive documentation for an organization's
                        codebase with the objective of preserving organizational
                        knowledge. The system ingests source code from various
                        code repositories such as Github, Gitlab, or manual
                        uploads. Through the application of advanced Natural
                        Language Processing (NLP) and Machine Learning (ML)
                        algorithms, the system generates detailed technical
                        documentation that encompasses all relevant aspects of
                        the codebase. The generated documentation not only
                        serves as a knowledge repository but also facilitates
                        collaboration among development teams and minimizes the
                        risk of crucial knowledge being monopolized. The use of
                        AI in this process results in efficient and automated
                        documentation generation, reducing the manual effort
                        required and ensuring that the documentation remains
                        up-to-date and in sync with the evolving codebase.
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="dropdown-object-2">
                <div
                  data-tb-track-faq-title="How is data storage calculated?"
                  data-tb-track-click="faq_item_expand"
                  data-w-id="314e4bfc-4277-dd23-5d45-75e96f60c79b"
                  className="dropdown-toggle"
                  onClick={() => handleClick(3)}
                >
                  <h4 className="m2-semibold">How is pricing calculated?</h4>
                  <div className="dropdown-icon-wrapper">
                    <img
                      src="6048c50adefe73fe3bc6c51b/614989f13316e24900485667_angledown.svg"
                      loading="lazy"
                      alt=""
                      className="pricing-dropdown-icon"
                    />
                  </div>
                </div>
                {activeIndex === 3 ? (
                  <div
                    styles="height:0px;opacity:0"
                    className="dropdown-list-2"
                  >
                    <div className="div-block-160">
                      <p className="s">
                        When it comes to pricing, we understand that every
                        organization is unique with its own set of needs and
                        requirements. That's why our pricing model is based on
                        the repository, number of files and number of versions,
                        making it tailored to your specific needs. And, if
                        you're looking for a bulk discount or have
                        enterprise-level requirements, don't hesitate to get in
                        touch with us. We'd be happy to work out a custom
                        solution that fits your needs.
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="dropdown-object-2">
                <div
                  data-tb-track-faq-title="How does price change as the amount of data grows?"
                  data-tb-track-click="faq_item_expand"
                  data-w-id="4bf63a92-f380-30a8-95b3-66fe6b547f53"
                  className="dropdown-toggle"
                  onClick={() => handleClick(4)}
                >
                  <h4 className="m2-semibold">
                    How does price change as the codebase grows?
                  </h4>
                  <div className="dropdown-icon-wrapper">
                    <img
                      src="6048c50adefe73fe3bc6c51b/614989f13316e24900485667_angledown.svg"
                      loading="lazy"
                      alt=""
                      className="pricing-dropdown-icon"
                    />
                  </div>
                </div>
                {activeIndex === 4 ? (
                  <div
                    styles="height:0px;opacity:0"
                    className="dropdown-list-2"
                  >
                    <div className="div-block-160">
                      <p className="s">
                        As your repository grows, the price of documentation
                        with Codesplain adjusts accordingly. The cost is
                        calculated based on the number of repositories, files,
                        and versions involved. To maximize the
                        cost-effectiveness of Codesplain, we suggest using it at
                        key milestones in your product development such as MVP,
                        Beta, Alpha, and when you're ready to go public. By
                        preserving your codebase knowledge at these critical
                        stages, you not only minimize costs, but also reduce
                        time and effort spent on documentation in the long run.
                        Contact us for bulk discounts and enterprise-level
                        requirements.
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="dropdown-object-2">
                <div
                  data-tb-track-faq-title="Do you charge per seat? "
                  data-tb-track-click="faq_item_expand"
                  data-w-id="c42350cd-9e84-0bb9-1abd-f6770414cec0"
                  className="dropdown-toggle"
                  onClick={() => handleClick(5)}
                >
                  <h4 className="m2-semibold">
                    Do you charge per repository?{" "}
                  </h4>
                  <div className="dropdown-icon-wrapper">
                    <img
                      src="6048c50adefe73fe3bc6c51b/614989f13316e24900485667_angledown.svg"
                      loading="lazy"
                      alt=""
                      className="pricing-dropdown-icon"
                    />
                  </div>
                </div>

                {activeIndex === 5 ? (
                  <div
                    styles="height:0px;opacity:0"
                    className="dropdown-list-2"
                  >
                    <div className="div-block-160">
                      <p className="s">
                        At Codesplain, we discovered that every organization's
                        codebase is unique and grows at its own pace. That's why
                        we've decided to charge per repository. This way, as
                        your codebase expands and evolves, so does your
                        documentation - without putting an unnecessary burden on
                        your budget. And, if you ever have any concerns about
                        pricing, just reach out to us. We're here to help!
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="dropdown-object-2">
                <div
                  data-tb-track-faq-title="Do you offer free trials or PoCs? "
                  data-tb-track-click="faq_item_expand"
                  data-w-id="318c1c24-5c48-e3c5-cf26-29c53061695c"
                  className="dropdown-toggle"
                  onClick={() => handleClick(6)}
                >
                  <h4 className="m2-semibold">Do you offer free trials? </h4>
                  <div className="dropdown-icon-wrapper">
                    <img
                      src="6048c50adefe73fe3bc6c51b/614989f13316e24900485667_angledown.svg"
                      loading="lazy"
                      alt=""
                      className="pricing-dropdown-icon"
                    />
                  </div>
                </div>
                {activeIndex === 6 ? (
                  <div
                    styles="height:0px;opacity:0"
                    className="dropdown-list-2"
                  >
                    <div className="div-block-160">
                      <p className="s">
                        Unfortunately, we do not currently offer free trials.
                        Generating detailed and accurate documentation requires
                        significant resources, and as a result, we are unable to
                        offer this service on a trial basis. However, we stand
                        behind our product and offer a{" "}
                        <a
                          href="https://codesplain.live/terms-and-conditions"
                          class="link"
                        >
                          refund policy
                        </a>{" "}
                        for those who are not completely satisfied with our
                        services.
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="dropdown-object-2">
                <div
                  data-tb-track-faq-title="Where do you store my data?"
                  data-tb-track-click="faq_item_expand"
                  data-w-id="d4ec4048-fd20-3802-c9ad-605ce31ef184"
                  className="dropdown-toggle"
                  onClick={() => handleClick(7)}
                >
                  <h4 className="m2-semibold">Where do you store my data?</h4>
                  <div className="dropdown-icon-wrapper">
                    <img
                      src="6048c50adefe73fe3bc6c51b/614989f13316e24900485667_angledown.svg"
                      loading="lazy"
                      alt=""
                      className="pricing-dropdown-icon"
                    />
                  </div>
                </div>
                {activeIndex === 7 ? (
                  <div
                    styles="height:0px;opacity:0"
                    className="dropdown-list-2"
                  >
                    <div className="div-block-160">
                      <p className="s">
                        At Codesplain, your code is always in safe hands! Our
                        system fetches the details of your code from popular
                        code repositories like Github, Gitlab, Bitbucket, or any
                        other place where your code resides. Once you give us
                        the go-ahead, our AI technology analyzes your code and
                        generates comprehensive documentation for you. The
                        security of your data is of utmost importance to us and
                        we make sure that it is in good hands. Our
                        infrastructure is hosted by a top cloud service provider
                        and we employ robust security standards to ensure the
                        safety of your code and its documentation.For further
                        information on data storage and security measures,
                        kindly refer to our{" "}
                        <a
                          href="/terms"
                          target="_blank"
                          class="link"
                        >
                          terms and conditions.
                        </a>
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="dropdown-object-2">
                <div
                  data-tb-track-faq-title="Can we use Tinybird on-premise? "
                  data-tb-track-click="faq_item_expand"
                  data-w-id="d437dfb8-1f82-3c8b-e4b2-6a91fff7dc81"
                  className="dropdown-toggle"
                  onClick={() => handleClick(8)}
                >
                  <h4 className="m2-semibold">
                    Can we use Codesplain on-premise?{" "}
                  </h4>
                  <div className="dropdown-icon-wrapper">
                    <img
                      src="6048c50adefe73fe3bc6c51b/614989f13316e24900485667_angledown.svg"
                      loading="lazy"
                      alt=""
                      className="pricing-dropdown-icon"
                    />
                  </div>
                </div>

                {activeIndex === 8 ? (
                  <div
                    styles="height:0px;opacity:0"
                    className="dropdown-list-2"
                  >
                    <div className="div-block-160">
                      <p className="s">
                      Regrettably, the on-premise option is not available at the moment. However, we are actively exploring this possibility and hope to offer it in the near future.
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="dropdown-object-2">
                <div
                  data-tb-track-faq-title="What type of support do you offer?"
                  data-tb-track-click="faq_item_expand"
                  data-w-id="33020bbb-e400-0a48-c00a-0f0c6eaa1ab3"
                  className="dropdown-toggle"
                  onClick={() => handleClick(9)}
                >
                  <h4 className="m2-semibold">
                    What type of support do you offer?
                  </h4>
                  <div className="dropdown-icon-wrapper">
                    <img
                      src="6048c50adefe73fe3bc6c51b/614989f13316e24900485667_angledown.svg"
                      loading="lazy"
                      alt=""
                      className="pricing-dropdown-icon"
                    />
                  </div>
                </div>

                {activeIndex === 9 ? (
                  <div
                    styles="height:0px;opacity:0"
                    className="dropdown-list-2"
                  >
                    <div className="div-block-160">
                      <p className="s">
                      We offer extensive support for Codesplain. Our dedicated support team is always available to assist you with any questions or issues you may have. This can include technical support, product training, and troubleshooting. If you need additional help, simply reach out to our support team, and we'll be happy to assist you.
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>

            {/* <Faq/> */}
          </div>
        </div>
      </div>
      <div className="getting-started wf-section">
        <div className="container w-container">
          <div className="cont-info">
            <h3 className="xl align-center mb-32">
              Ready to document & preserve your codebase knowledge?
            </h3>
            <div className="cont-button align-center">
              <a
                data-tb-track-section="footer-cta"
                data-tb-track-click="signup_cta_click"
                href="https://app.codesplain.live/"
                className="main-button secondary w-inline-block"
              >
                <p>Create Codesplain</p>
                <img
                  src="/pricing/icon-check.png"
                  loading="lazy"
                  alt=""
                  className="arrow-right-apply"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
