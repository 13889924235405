import './App.css';
import Body from './app/pages/Body_Navbar';
import React from 'react';
import Pricing from './app/pages/Pricing';
import Navbar from './app/components/Navbar';
import Footer from './app/components/Footer';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Product from './app/pages/Product';
import Why from './app/pages/Why';
import Terms from './app/Terms';
import BlogOneTitle from './app/pages/BlogOneTitle';
import BlogTwoTitle from './app/pages/BlogTwoTitle';
import BlogThreeTitle from './app/pages/BlogThreeTitle';


function App() {
  return (
    <Router>
    <div className="container-fluid">
    {/* <EventMsg /> */}
    <Navbar/>
      <Routes>
      <Route exact path="/" element={<Body/>}/>
      <Route path="/Product" element={<Product/>}/>
      <Route path="/Why" element={<Why/>}/>
      <Route path="/Pricing" element={<Pricing/>}/>
      <Route path="/Terms" element={<Terms/>}/>
      <Route path="/BlogOneTitle" element={<BlogOneTitle/>}/>
      <Route path="/BlogTwoTitle" element={<BlogTwoTitle/>}/>
      <Route path="/BlogThreeTitle" element={<BlogThreeTitle/>}/>
      </Routes> 
      <Footer/>
    </div>
    </Router>
  );
}

export default App;
