import React from 'react'

export default function Why() {
  return (
    <div className="wrapper-content">
      <div className="hero-beliefs bg-black wf-section">
        <div className="container-snd w-container">
          <div className="cont-title">
            
              <h1 className="xl text-white align-center in-64">Why Codesplain is Required?</h1>
            
          </div>
          <div className="beliefs-subtitle">
            <p className="s text-violet align-center">Imagine a world where code knowledge is easily accessible, and
              development is accelerated, where hours are saved, and collaboration thrives. This is not just a dream, but
              a reality with the right documentation. <br />Here is why,
            
            </p>
          </div>
          <div className="wrapper-lines">
            
              <div data-w-id="d98a95ad-69a6-2faa-74a0-0246821dee9a" styles="opacity:0" className="icon-beliefs"><img
                src="./why-Codesplain/seven.png" loading="lazy" alt="" className="image-28" />
              </div>
            
            <div className="beliefs-line hidden-mobile"><img src="/why-Codesplain/line.svg" loading="lazy" alt="" />
            </div>
            <div data-w-id="41697669-55be-eece-3950-d3812e17a7f9" styles="opacity:0" className="beliefs-text">
              
                <h3 className="body-l">"The Beginning": An organization starts with a new project and as the codebase grows, it
                  becomes increasingly difficult to understand and manage.</h3>
              
            </div>
            
              <div className="beliefs-line"><img src="/why-Codesplain/line2.svg" loading="lazy" alt=""
                className="desktop-line" /><img src="/why-Codesplain/line_60.svg" loading="lazy" alt=""
                  className="mobile-line" /></div>
            
            
              <div data-w-id="aa215e15-401b-350c-653c-57854900af7f" styles="opacity:0" className="icon-beliefs"><img
                src="./why-Codesplain/seven-1.png" loading="lazy" alt="" /></div>
              <div className="beliefs-line hidden-mobile"><img src="/why-Codesplain/line.svg" loading="lazy" alt="" />
              </div>
            

            <div data-w-id="2e8c6dde-69f3-042f-2a55-b330d27c1206" styles="opacity:0" className="beliefs-text">
              
                <h3 className="body-l">"The Struggle": Without proper documentation, developers spend countless hours trying to
                  understand the code, leading to increased time and effort, and reduced productivity.</h3>
              

            </div>

            
              <div className="beliefs-line"><img src="/why-Codesplain/line2.svg" loading="lazy" alt=""
                className="desktop-line" /><img src="/why-Codesplain/line_60.svg" loading="lazy" alt=""
                  className="mobile-line" /></div>
            

            

              <div data-w-id="3829877e-bfaa-576e-674d-3fe75ce0e194" styles="opacity:0" className="icon-beliefs"><img
                src="./why-Codesplain/seven-2.png" loading="lazy" alt="" /></div>
              <div className="beliefs-line hidden-mobile"><img src="/why-Codesplain/line2.svg" loading="lazy" alt="" />
              </div>
            


            <div data-w-id="5acf7578-d858-9112-d7d0-4880e26af429" styles="opacity:0" className="beliefs-text">
              
                <h3 className="body-l">"The Pain Points": As the codebase grows, knowledge becomes monopolized by a few
                  individuals, and onboarding new developers becomes a challenge.</h3>
              

            </div>

            

              <div className="beliefs-line"><img src="/why-Codesplain/line.svg" loading="lazy" alt=""
                className="image-29" /><img src="/why-Codesplain/line_60.svg" loading="lazy" alt="" className="mobile-line" />
              </div>
            

            
              <div data-w-id="054e9048-5fb5-ce40-ad09-e7b7d333ebbc" styles="opacity:0" className="icon-beliefs"><img
                src="./why-Codesplain/seven-3.png" loading="lazy" alt="" /></div>
            

            <div data-w-id="f40f89f3-01d6-fd3f-d2e4-4d1eb7b71602" styles="opacity:0" className="beliefs-text">
              
                <h3 className="body-l">"The Lightbulb Moment": The organization realizes the importance of documentation and
                  starts exploring solutions to document their code.</h3>
              

            </div>
            

              <div className="beliefs-line"><img src="/why-Codesplain/line2.svg" loading="lazy" alt=""
                className="desktop-line" /><img src="/why-Codesplain/line_60.svg" loading="lazy" alt=""
                  className="mobile-line" /></div>
            

            

              <div data-w-id="a14df65b-d942-f4c2-ef38-a6cf48fc6186" styles="opacity:0" className="icon-beliefs"><img
                src="./why-Codesplain/seven-4.png" loading="lazy" alt="" /></div>
              <div className="beliefs-line hidden-mobile"><img src="/why-Codesplain/line.svg" loading="lazy" alt="" />
              </div>
            

            <div data-w-id="9558a329-36ef-fb27-76ab-d8e46a3b92f3" styles="opacity:0" className="beliefs-text">
              
                <h3 className="body-l">"The Discovery": The organization discovers Coodebook, an AI-powered documentation
                  generation tool, and decides to give it a try.</h3>
              

            </div>
            

              <div className="beliefs-line"><img src="/why-Codesplain/line2.svg" loading="lazy" alt="" /></div>
              <div data-w-id="998e8219-b770-1ead-3daf-1904f75e0ad6" styles="opacity:0" className="icon-beliefs"><img
                src="./why-Codesplain/seven-4.png" loading="lazy" alt="" /></div>
            

            
              <div className="beliefs-line hidden-mobile"><img src="/why-Codesplain/line.svg" loading="lazy" alt="" />
              </div>
            

            <div data-w-id="030ed1e5-11c0-a481-4af9-72a83fc863bb" styles="opacity:0" className="beliefs-text">
              

                <h3 className="body-l">"The Transformation": With Codesplain, the organization generates comprehensive and
                  easily understandable documentation, saving time and effort, and improving collaboration within the
                  development team.</h3>
              

            </div>

            
              <div className="beliefs-line"><img src="/why-Codesplain/line2.svg" loading="lazy" alt="" /></div>
              <div data-w-id="998e8219-b770-1ead-3daf-1904f75e0ad6" styles="opacity:0" className="icon-beliefs"><img
                src="./why-Codesplain/seven-4.png" loading="lazy" alt="" /></div>
              <div className="beliefs-line hidden-mobile"><img src="/why-Codesplain/line.svg" loading="lazy" alt="" />
              </div>
            

            <div data-w-id="030ed1e5-11c0-a481-4af9-72a83fc863bb" styles="opacity:0" className="beliefs-text">
              
                <h3 className="body-l">"The Happy Ending": The organization sees the benefits of well-documented code, including
                  reduced knowledge monopolization, improved collaboration, and increased efficiency. They are now able to
                  scale and grow their business with ease.</h3>
              

            </div>
            <div className="beliefs-line"><img src="/why-Codesplain/line2.svg" loading="lazy" alt=""
              className="desktop-line" /><img src="/why-Codesplain/line_60.svg" loading="lazy" alt=""
                className="mobile-line" /></div>
            <div className="div-block-109" image-50>
              
                <h2 className="slack-title">Get Codesplain</h2>
              

                <div className="text-block-17">
              
                  <div className="s text-white">Save your technical knowledge for everyone before its too late. Connect your
                    github, gitlab or upload
                    the code to start building comprehensive
                    documentation. </div>
              

                </div>
              
                <div className="div-block-16"><a href="/" target="_blank" className="main-button-2 secondary w-button">Create
                  Documentation</a></div>
              

            </div>
          
          </div>
        </div>
      </div>
    </div>
  );
}
