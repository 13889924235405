import React, { useState } from 'react'

export default function Product() {

  const [firstButton, setFirstButton] = useState(true);
  const [secondButton, setSecondButton] = useState(false);

  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? 0 : index);
  }


  const handleFirstButon = (e) => {
    setFirstButton(true)
    setSecondButton(false)
  }

  const handleSecondButton = (e) => {
    setFirstButton(false);
    setSecondButton(true);
  }

  const className = firstButton ? "con-text-tab-dark" : 'con-text-tab';
  const secondClassName = !secondButton ? 'con-text-tab' : 'con-text-tab-dark';

  const textStyleOne = firstButton ? "text-block-white" : "text-block-7";
  const textStyleTwo = !secondButton ? "text-block-7" : "text-block-white";



  return (
    <div className="wrapper-content">

      <div classNameName="hero bg-ligh-grey wf-section">
        <div className="container w-container">
          <div className="cont-title maxw">
            <h1 className="xl align-center mb-16"><strong>The modern </strong><span className="underline-text"><strong>way
              to</strong></span><strong> <br /></strong><span className="underline-text"><strong> secure code
              </strong></span><strong> knolwedge</strong><br /></h1>
            <div className="cont-subtitle">
              <p className="s text-grey align-center"><u>Preserve and document your code knowledge</u> with
                <b>Code<i>splain</i> </b>to
                enhance
                collaboration within your development team and prevent critical information from being lost.
                Well-documented codebase is <i>peace of mind.</i></p>
            </div>
          </div>
        </div>
        <div className="container-wide w-container">
          <div className="img-header-copy">
            <div data-duration-in="300" data-duration-out="100" data-current="Tab 1" data-easing="ease"
              className="tabs-header w-tabs">
              <div className="tabs-menu-6 w-tab-menu">

                <button href="#" data-w-tab="Tab 1"  data-w-id="109620e1-07ff-e6e1-196c-478680d0d8b8"
                  className="tab w-inline-block w-tab-link w--current">
                  <div className={className} id="dark" onClick={handleFirstButon}>
                    <div className={textStyleOne}>Without Codesplain</div>
                  </div>
                  <div className="cont-subtitle-tab">
                  </div>

                </button>

                  <div className={secondClassName} id="light" onClick={handleSecondButton}>
                    <div className={textStyleTwo}>With Codesplain</div>
                  </div>
                  <div className="cont-subtitle-tab" >
                  </div>
                <div className='mt-5'>
                  {firstButton ? <div>
                    <img src="https://i.postimg.cc/tC56dQJG/without-codesplain.png" className='img-fluid' style={{ height: '60vh' }} alt="img"/>
                  </div> : <div>
                    <img src="https://i.postimg.cc/5yJHvnMt/with-codesplain.png" className='img-fluid' style={{ height: '60vh' }} alt="img"/>

                  </div>}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="target wf-section">
        <div className="cont-text mb-104">
          <h4 className="xs-ia align-center mb-8">People Friendly Documentation</h4>
          <h2 className="xl align-center"><span className="underline-text"><strong> Document that is Essential, Effortless,
            Engaging,
          </strong></span><span className="underline-text"><strong>Exaustive</strong></span><strong> and </strong><span
            className="underline-text"><strong>Effective</strong></span></h2>
        </div>

        <div className="target-content-copy">
          <div className="container w-container">
            <div className="div-block-32">

              <div className="target-columns-copy w-row">
                <div className="target-column w-col w-col-6 w-col-stack">
                  <div className="featured js-active">
                    <div className="featured-bar" onClick={() => handleClick(0)} style={{cursor: 'pointer'}}>
                      <div className="featured-icon">
                        <div className="icon-shadow"></div>
                        <div className="icon-wrap bg-green"><img src="/products/check.svg" loading="lazy" alt=""
                          className="check" /></div>
                      </div>
                      <div className="featured-text">
                        <p className="featured-title">Essential</p>
                      </div> 
                    </div>
                    {activeIndex === 0 ? (
                      <div className="featured-content-wrapper">
                        <div className="featured-content">
                          <p className="paragraph-4">Codesplain is essential for organizations as it provides comprehensive
                            documentation that is crucial for preserving code knowledge, reducing the risk of knowledge
                            being monopolized, and improving collaboration within the development team.</p>
                        </div>
                      </div>
                    ) : null}

                  </div>
                  <div className="featured">
                    <div className="featured-bar" onClick={() => handleClick(1)} style={{cursor:'pointer'}}>
                      <div className="featured-icon">
                        <div className="icon-wrap bg-watergreen"><img src="/products/check.svg" loading="lazy" alt=""
                          className="check" /></div>
                        <div className="icon-shadow"></div>
                      </div>
                      <div className="featured-text">
                        <p className="featured-title">Effortless</p>
                      </div>
                    </div>
                    {activeIndex === 1 ? (
                      <div className="featured-content-wrapper">
                        <div className="featured-content">
                          <p className="paragraph-4">With Codesplain, generating documentation becomes effortless and
                            hassle-free. The AI-powered tool ingests code from Github, Gitlab, or even manual uploads, and
                            generates detailed documentation in no time.</p>
                        </div>
                      </div>
                    ) : null}

                  </div>
                  <div className="featured">
                    <div className="featured-bar" onClick={() => handleClick(2)} style={{cursor:'pointer'}}>
                      <div className="featured-icon">
                        <div className="icon-wrap bg-lighblue"><img src="/products/check.svg" loading="lazy" alt=""
                          className="check" /></div>
                        <div className="icon-shadow"></div>
                      </div>
                      <div className="featured-text">
                        <p className="featured-title">Engaging</p>
                      </div>
                    </div>

                    {activeIndex === 2 ? (
                      <div className="featured-content-wrapper">
                        <div className="featured-content">
                          <p className="paragraph-4">Codesplain's documentation is not only comprehensive but also engaging and
                            easily understandable. This makes it easier for developers to understand the code and get up to
                            speed with new projects, saving time and effort.
                          </p>
                        </div>
                      </div>
                    ) : null}

                  </div>
                  <div className="featured">
                    <div className="featured-bar" onClick={() => handleClick(3)} style={{cursor:'pointer'}}>
                      <div className="featured-icon">
                        <div className="icon-wrap bg-blue"><img src="/products/check.svg" loading="lazy" alt=""
                          className="check" /></div>
                        <div className="icon-shadow"></div>
                      </div>
                      <div className="featured-text">
                        <p className="featured-title">Exaustive</p>
                      </div>
                    </div>

                    {activeIndex === 3 ? (
                      <div className="featured-content-wrapper">
                        <div className="featured-content">
                          <p className="paragraph-4">Codesplain generates exhaustive documentation, covering every aspect of the
                            codebase. This ensures that no critical information is lost and the codebase remains
                            well-documented, even as it evolves and grows.</p>
                        </div>
                      </div>
                    ) : null}

                  </div>
                </div>


                <div className="target-column u-img-align--center w-col w-col-6 w-col-stack">

                  {activeIndex === 0 ? (
                    <div className="target-images">
                      <img src="https://i.postimg.cc/0jym6Vvq/click-4-p-1600-1.png" className='img-fluid' style={{ height: '55vh' }} alt="img"/>
                    </div>
                  ) : activeIndex === 1 ? (<div className="target-images">
                    <img src="https://i.postimg.cc/BvfK5S3p/click-4-p-1600-2.png" className='img-fluid' style={{ height: '55vh' }}  alt="img" />
                  </div>) : activeIndex === 2 ? (<div className="target-images">
                    <img src="https://i.postimg.cc/rFd0v0Fg/click-4-p-1600-3.png" className='img-fluid' style={{ height: '55vh' }}  alt="img" />
                  </div>) : activeIndex === 3 ? (<div className="target-images">
                    <img src="https://i.postimg.cc/k5VwhXRb/click-4-p-1600-4.png" className='img-fluid' style={{ height: '55vh' }}  alt="img"/>
                  </div>) : null}
                 
                </div>
              </div>
            </div>
            <div className="container w-container">
              <div className="cont-title maxw">
                <h1 className="xl align-center mb-16"><strong />& Effective</h1>
                <div className="cont-subtitle">
                  <p className="s text-grey align-center">Say goodbye to lost code knowledge and ineffective collaboration -
                    Codesplain is here to revolutionize the way your organization preserves and shares its codebase. With
                    its cutting-edge AI technology, documentation generation is no longer a time-consuming task, but a
                    streamlined solution that saves hours, reduces the risk of monopolized knowledge, and brings your
                    development team closer together.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card wf-section">
        <div className="container-snd w-container">
          <div className="w-layout-grid grid-2">
            <div className="card-cont">
              <h4 className="s bold mb-12">1. Connect Codebase</h4>
              <p className="s text-lightgrey">The first step is to connect your codebase to Codesplain. You can connect your Github, Gitlab, Bitbucket, or even manually upload your code files. This allows Codesplain to access your code and start generating explanations.</p>
            </div>
            <div className="card-cont">
              <h4 className="s bold mb-12"><strong><b>2.</b> Select Files</strong></h4>
              <p className="s text-lightgrey">The first step is to connect your codebase to Codesplain. You can connect your Github, Gitlab, Bitbucket, or even manually upload your code files. This allows Codesplain to access your code and start generating explanations.</p>
            </div>
            <div className="card-cont">
              <h4 className="s bold mb-12"><b>3.</b> Generate Docs</h4>
              <p className="s text-lightgrey">The first step is to connect your codebase to Codesplain. You can connect your Github, Gitlab, Bitbucket, or even manually upload your code files. This allows Codesplain to access your code and start generating explanations.</p>
            </div>
            <div className="card-cont">
              <h4 className="s bold mb-12"><b>4.</b> Publish</h4>
              <p className="s text-lightgrey">The first step is to connect your codebase to Codesplain. You can connect your Github, Gitlab, Bitbucket, or even manually upload your code files. This allows Codesplain to access your code and start generating explanations.</p>
            </div>
            <div className="card-cont">
              <h4 className="s bold mb-12"><strong><b>5.</b> Invite & Collabrate</strong></h4>
              <p className="s text-lightgrey">The first step is to connect your codebase to Codesplain. You can connect your Github, Gitlab, Bitbucket, or even manually upload your code files. This allows Codesplain to access your code and start generating explanations.</p>
            </div>
            <div className="card-cont">
              <h4 className="s bold mb-12"><strong><b>6.</b> Update Docs</strong></h4>
              <p className="s text-lightgrey">Codesplain provides a need-based service to update the document whenever required. This means that as your codebase evolves and grows, your documentation will always remain up-to-date and comprehensive.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="section-6 wf-section">
        <div className="container">
          <div className="div-block-109" image-50>
            <h2 className="slack-title">Connect Github</h2>
            <div className="text-block-17">
              <div className="s text-white">Save your technical knowledge for everyone before its too late. Connect your
                github, gitlab or upload
                the code to start building comprehensive
                documentation. </div>
            </div>
            <div className="div-block-16"><a href="https://app.codesplain.live/" target="_blank"  rel="noopener noreferrer" className="main-button-2 secondary w-button">Create
              Documentation</a></div>
          </div>
        </div>
      </div>

    </div>

  )
}
